<template>
  <div class="page">
      <div class="header">
          <div @click="goIndex" class="logo"></div>
          <div class="operate">
              <div v-if="showInfo" @click="goBack" class="btn back"></div>
              <div @click="goWelcome" class="btn welcome"></div>
              <div @click="goIndex" class="btn home"></div>
              <div @click="goSend" class="btn send"></div>
          </div>
      </div>
      <div class="main">
          <div class="a">
              <div class="top">
                  <div class="left">
                      <div class="about">About us</div>
                      <div class="name">公司简介</div>
                  </div>
                  <div class="right">
                        <div class="title">行业领先的血管介入类医疗器械平台企业</div>
                      <div class="desc">
                          广东博迈医疗科技股份有限公司创立于2012年，专注于高性能血管介入医疗器械和治疗技术的研发、生产和全球销售，是一家技术行业领先的医疗器械平台型跨国集团公司。公司总部位于广东省东莞市松山湖高新技术产业开发区，在中国东莞、深圳、上海、株洲，以及荷兰、新加坡、印度等地分别设有子公司、生产基地、研发中心和办事处。
                      </div>
                  </div>
              </div>
                <div class="list">
                    <div class="item">
                        <div class="icon">
                            <img src="../assets/youzhi.png">
                        </div>
                        <div>
                            <div class="item-name">优质产品</div>
                            <div class="item-desc">创新的血管介入技术和丰富的产品系列</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="icon">
                            <img src="../assets/dingjian.png">
                        </div>
                        <div>
                            <div class="item-name">顶尖团队</div>
                            <div class="item-desc">全球顶尖的医疗器械研发、制造团队</div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="icon">
                            <img src="../assets/quanqiu.png">
                        </div>
                        <div>
                            <div class="item-name">全球标准</div>
                            <div class="item-desc">符合全球最高标准的质量、法规要求</div>
                        </div>
                    </div>
                </div>
              <div class="pic">
                  <div class="cover"></div>
                  <div class="content">
                      <div class="pic-name">我们的使命</div>
                      <div class="pic-desc">科技拥抱生命</div>
                      <div class="pic-name" style="margin-top: 120px">我们的愿景</div>
                      <div class="pic-desc">创新优质的医疗器械和技术、打造有声望的医疗科技跨国集团</div>
                  </div>
              </div>
          </div>
          <div class="b">
              <div class="name">公司荣誉</div>
              <img class="pic" src="../assets/rongyu.png">
          </div>
          <div class="c">
              <div class="name">联系我们</div>
              <div class="top">
                  <div class="left">
                      <div class="title">广东博迈医疗科技股份有限公司</div>
<!--                      <div class="small">BrosMed Medical Co.,Ltd.</div>-->
                      <div class="desc">
                          地址：广东省东莞松山湖园区工业北四路1号<br>
                          电话：+ 86（769）2289 2018<br>
                          销售服务热线电话：18188896342(王小姐 销售副总助理)<br>
                          邮箱：businesscn@brosmed.com<br>
                          邮编：523808<br>
                          传真：+ 86（769）2289 2016
                      </div>
                  </div>
                  <div class="right">
                      <div class="item">
                          <img class="code" src="../assets/erweima.png">
                          <div class="title">扫一扫关注我们</div>
                      </div>
                      <div class="item">
                          <img class="code" src="../assets/erweima_2.png">
                          <div class="title">销售服务企业微信</div>
                      </div>
                  </div>
              </div>
              <div class="list">
                  <div class="item">
                      <div class="cover">
                          <img src="../assets/image_1.png">
                      </div>
                      <div class="content">
                          <div class="item-name">BrosMed Medical B.V.（荷兰分公司）</div>
                          <div class="item-desc">
                              地址：Mgr. Buckxstraat 8,6134 AP Sittard The Netherlands<br>
                              电话：+ 31 850 140 901<br>
                              邮箱：cs@brosmed.com
                          </div>
                      </div>
                  </div>
                  <div class="item">

                      <div class="content">
                          <div class="item-name">广东博迈本尚医疗科技有限公司</div>
                          <div class="item-desc">
                              地址：深圳市龙华区观澜街道新澜社区观光路1301号银星 科技大厦D906<br>
                              电话：0755-23739453 <br>
                              邮箱：davisxu@brosmed.com
                          </div>
                      </div>
                      <div class="cover">
                          <img src="../assets/image_2.png">
                      </div>
                  </div>
                  <div class="item">
                      <div class="cover">
                          <img src="../assets/image_3.png">
                      </div>
                      <div class="content">
                          <div class="item-name">广东博迈元通医疗科技有限公司</div>
                          <div class="item-desc">
                              地址：广东省东莞市松山湖园区工业北四路1号10栋201室 <br>
                              联系人：张志军 <br>
                              电话：0769-22892018-8605 <br>
                              邮箱：wade@brosmed.com
                          </div>
                      </div>
                  </div>
                  <div class="item">

                      <div class="content">
                          <div class="item-name">湖南博润医疗器械有限公司</div>
                          <div class="item-desc">
                              地址：湖南省株洲市经济开发区菖塘路88号创业创新园 B8栋、B5栋<br>
                              联系人：旷冰宾 <br>
                              电话：13786135506 <br>
                              邮箱：bingbin.kuang@brosmed.com
                          </div>
                      </div>
                      <div class="cover">
                          <img src="../assets/image_4.png">
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>
<script>
import {useRouter} from 'vue-router'
export default {
    name: "About",

    setup() {
        const router = useRouter()
        const goIndex = () => {
            router.push("/")
        }
        const goWelcome = () => {
            router.push("/welcome")
        }
        const goSend = () => {
            router.push({path:"/",query:{showSend:true}})
        }
        return{
            goIndex,
            goWelcome,
            goSend
        }
    }
}
</script>
<style scoped>
.page{
    background-image: url("../assets/bg_liebiao.jpg");
    background-size: cover;
    overflow: hidden;
}

.logo{
    width: 235px;
    height: 93px;
    background: url("../assets/logo_2.png");
    background-size: 100%;


}
.header{
    display: flex;
    justify-content: space-between;

    padding: 35px 55px;
}
.operate{
    display: flex;
}
.operate .btn{
    width: 76px;
    height: 76px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    margin: 0 60px;
}
.operate .btn.welcome{
    background-image: url("../assets/come.png");
}
.operate .btn.home{
    background-image: url("../assets/home.png");
}
.operate .btn.send{
    background-image: url("../assets/send.png");
}
.operate .btn.back{
    background-image: url("../assets/back.png");
}

.main{
    height: calc(100% - 163px - 30px);
    background: #FFFFFF;
    border: 2px solid #267BDA;
    box-shadow: -10px 0px 8px 0px rgba(0,90,201,0.53);
    border-radius: 66px;

    margin: 0 30px 30px;
    box-sizing: border-box;

    overflow-y: scroll;
}
.a{
    padding: 150px 230px;
    background: url("../assets/bg_logo.png") no-repeat;
}
.a .top{
    display: flex;
}
.a .left{
    width: 100%;
}
.a .top .right{
    flex-shrink: 0;
    width: 1105px;
}
.a .about{
    font-size: 140px;
    font-family: Source Han Sans CN;
    font-weight: 800;
    color: #0451C5;
    margin-bottom: 80px;
}
.a .name, .b .name, .c .name{
    font-size: 80px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0451C5;
    line-height: 100px;
}
.a .name::before, .b .name::before, .c .name::before{
    display: block;
    content: '';
    width: 86px;
    height: 9px;
    background: #0451C5;
    margin-bottom: 55px;
}
.a .title{
    font-size: 54px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
}
.a .desc{
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 64px;

    margin-top: 80px;
}
.a .list{
    margin: 110px -40px 0;
    display: flex;
}
.a .item{
    width: calc((100% - 240px) / 3);
    margin: 0 40px;
    display: flex;
}
.a .item .icon{
    flex-shrink: 0;
    width: 100px;
    height: 100px;
    margin-right: 25px;
}
.a .item .icon img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.a .item-name{
    font-size: 50px;
    font-family: MiSans;
    font-weight: 600;
    color: #0451C5;
}
.a .item-desc{
    font-size: 32px;
    font-family: MiSans;
    font-weight: 400;
    color: #333333;
}
.a .pic{
    height: 769px;
    display: flex;
    margin-top: 100px;
}
.a .pic .cover{
    width: 50%;
    height: 100%;
    background: url("../assets/pic_cp.png");
    background-size: cover;
}
.a .pic .content{
    width: 50%;
    height: 100%;
    padding: 100px 110px;
    box-sizing: border-box;
    background: url("../assets/bg_shiming.png");
    background-size: cover;
}
.a .pic .pic-name{
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: 800;
    color: #FFFFFF;
    line-height: 46px;
}
.a .pic .pic-desc{
    font-size: 66px;
    font-family: Source Han Sans CN;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 94px;
    margin-top: 40px;
}
.b .name{
    margin: 0px 230px;
}
.b .pic{
    width: 100%;
    margin-top: 60px;
}
.c{
    padding: 170px 230px;
    background: url("../assets/bg_lianxi.jpg") no-repeat  0 350px ;
    background-size: 100% auto;
}
.c .top{
    height: 610px;
    /*background: #FFFFFF;*/
    box-shadow: 0px 4px 27px 0px rgba(49,49,49,0.11);
    display: flex;
    margin-top: 300px;
}
.c .top .left{
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    padding: 80px 100px;
    box-sizing: border-box;
    background: #FFF;
}
.c .left .title{
    font-size: 46px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0451C5;
    line-height: 46px;
}
.c .left .small{
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #757575;
    line-height: 28px;
    margin-top: 20px;
}
.c .left .desc{
    font-size: 34px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 52px;
    margin-top: 80px;
}
.c .right{
    width: 50%;
    height: 100%;
    background: url("../assets/bg_lianxi.png");
    background-size: cover;
    padding: 160px 230px;
    box-sizing: border-box;

    display: flex;
    justify-content: space-between;
}
.c .right .item{
    width: 300px;
}
.c .right .code{
    width: 220px;
    height: 220px;
    display: flex;
    margin: auto;
}
.c .right .title{
    font-size: 32px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 112px;
    text-align: center;
}
.c .list{
    margin-top: 200px;
}
.c .list .item{
    height: 610px;
    display: flex;
}
.c .item .cover{
    width: 50%;
    height: 100%;
}
.c .item .cover img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.c .item .content{
    width: 50%;
    height: 100%;
    box-sizing: border-box;
    padding: 95px 115px;
}
.c .item .item-name{
    font-size: 46px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0451C5;
    line-height: 46px;
}
.c .item .item-desc{
    font-size: 32px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 46px;
    margin-top: 70px;
}
</style>
