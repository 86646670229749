<template>
<div class="page">
    <div class="logo"></div>
    <div class="login-wrap">
        <div class="title">
            博迈医疗系统登录
        </div>
        <div class="input-group user">
            <input placeholder="用户名" v-model="loginForm.username"/>
        </div>
        <div class="input-group password">
            <input placeholder="密码" type="password" v-model="loginForm.password"/>
        </div>
        <div class="btn" @click="goWelcome">登录</div>
    </div>
    <div class="copyright">Copyright © 2020 广东博迈医疗科技股份有限公司 All rights reserved.</div>
</div>
    <div class="notice" v-if="showNotice">{{notice}}</div>
</template>

<script setup>
import {useRouter} from 'vue-router'
import {reactive, ref} from 'vue'
import axios from 'axios'

        const router = useRouter();
        const loginForm = reactive({
            username:"",password:""
        })
        const showNotice = ref(false);
        const notice = ref("用户名或密码不正确");
        const goWelcome = () => {
            axios.post("/user/login", {...loginForm}).then(({data}) => {
                if (data.code == 200) {
                    window.localStorage.setItem("user", JSON.stringify(data.data));
                    router.push("/welcome")
                } else if(data.code != -1) {
                    notice.value=data.msg;
                    showNotice.value=true;
                    setTimeout(function (){
                        showNotice.value = false;
                    },1500);
                }
            })
        }

</script>

<style scoped>
.page{
    background: url("../assets/bg_3.jpg") center;
    background-size: cover;

    position: relative;
    overflow: hidden;
}
.logo{
    width: 216px;
    height: 86px;
    background: url("../assets/logo_3.png");

    position: absolute;
    top: 75px;
    left: 75px;
}
.login-wrap{
    width: 1918px;
    height: 1191px;
    background: url("../assets/bg_5.png");

    padding: 200px 0 0 1100px;
    box-sizing: border-box;

    margin: 220px auto 0;
}
.login-wrap .title{
    font-size: 60px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #0451C5;
    line-height: 85px;
}
.input-group{
    width: 604px;
    height: 100px;
    border: 2px solid rgba(73,201,255,0.48);
    background: #FFFFFF;
    box-shadow: 0px 6px 6px 0px rgba(51,94,204,0);
    border-radius: 10px;
    background-origin: border-box;
    background-clip: content-box, border-box;

    display: flex;
    align-items: center;

    position: relative;

    margin-top: 80px;
}

.input-group input{
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0451C5;
    line-height: 48px;

    outline:none;
    border: 0;
}
.input-group.user::before{
    display: block;
    content: '';
    width: 56px;
    height: 56px;
    background: url("../assets/name.png") no-repeat;
    margin-right: 20px;
    margin-left: 30px;
}
.input-group.password::before{
    display: block;
    content: '';
    width: 56px;
    height: 56px;
    background: url("../assets/mima.png") no-repeat;
    margin-right: 20px;
    margin-left: 30px;
}
.input-group input::-webkit-input-placeholder {
    /* WebKit browsers，webkit内核浏览器 */
    color: #0451C5;
}
.login-wrap .btn{
    width: 287px;
    height: 91px;
    background: #0451C5;
    border: 2px solid #FFFFFF;
    border-radius: 20px;

    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 48px;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 50px;
}
.copyright{
    width: 100%;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 48px;
    text-shadow: 0px 2px 4px #0451C5;
    text-align: center;

    position: absolute;
    bottom: 35px;

}
.notice{
    width: 553px;
    height: 96px;
    background: rgba(0,0,0,0.5);
    border-radius: 48px;

    font-size: 36px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 96px;
    text-align: center;

    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -276px;
    margin-top: -48px;
}
</style>
