<template>
    <div class="fill" >
        <div class="wrapper">
            <div class="fill-content">
                <div class="title">修改密码</div>
                <div class="input-group" style="margin-top: 80px">
                    <input v-model="param.oldPassword" placeholder="请输入原密码" type="password"/>
                </div>
                <div class="input-group">
                    <input v-model="param.newPassword"  placeholder="请输入新密码" type="password"/>
                </div>
                <div class="input-group">
                    <input v-model="param.again"  placeholder="请再次输入新密码" type="password"/>
                </div>
                <div class="btn" @click="doSave">发送</div>
            </div>
            <div class="fill-close" @click="close"></div>
        </div>
    </div>
</template>

<script setup>
import {defineEmits, ref} from 'vue'
import axios from 'axios'

const emit = defineEmits(["close"]);
const close = ()=>{
    emit("close");
}
const param = ref({
    oldPassword:"",
    newPassword:"",
    again:""
})

const doSave = () => {
    console.log(param)
    if(param.value.newPassword && param.value.oldPassword && param.value.again){
        if(param.value.newPassword != param.value.again){
            alert("两次密码输入不一致");
        }else{
            axios.post("/user/upPassword", {...param.value}).then(({data}) => {
                if (data.code == 200) {
                    alert("保存成功");
                    emit("close");
                } else if(data.code != -1) {
                    alert(data.msg);
                }
            });
        }
    }
}
</script>

<style scoped>
.fill{
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);

    display: flex;
    align-items: center;
    justify-content: center;
}
.fill .wrapper{
    width: 760px;
    height: fit-content;
    background: rgba(255,255,255,0.5);
    padding: 20px;
    border-radius: 35px;
    box-sizing: border-box;
    position: relative;
}
.fill .fill-content{
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 29px 125px 0px rgba(51,94,204,0.52);
    border-radius: 30px;

    padding: 80px 85px 110px;
    box-sizing: border-box;
}
.fill .title{
    font-size: 60px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0451C5;
    line-height: 46px;
    text-align: center;
}
.fill .input-group{
    width: 100%;
    height: 101px;
    background: #FFFFFF;
    border: 2px solid #0087D5;
    box-shadow: 0px 6px 6px 0px rgba(51,94,204,0);
    border-radius: 10px;
    margin-top: 50px;
    padding: 0 25px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.fill .input-group input{
    width: 100%;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0451C5;
    line-height: 48px;

    outline:none;
    border: 0;
}
/*.fill .input-group input::-webkit-input-placeholder {*/
/*    !* WebKit browsers，webkit内核浏览器 *!*/
/*    color: #0451C5;*/
/*}*/
.fill .btn{
    width: 287px;
    height: 91px;
    background: #0451C5;
    border-radius: 20px;

    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 91px;
    text-align: center;

    margin: 80px auto 0;
}
.fill .fill-close{
    width: 162px;
    height: 162px;
    background: url("../assets/guanbi_2.png");
    position: absolute;
    right: -60px;
    top: -60px;
}
</style>
