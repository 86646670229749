<template>
  <div class="page">
      <div class="header">
          <div @click="goIndex" class="logo"></div>
          <div class="operate">
<!--              <div v-if="showInfo" @click="goBack" class="btn back"></div>-->
              <div @click="goWelcome" class="btn welcome"></div>
              <div @click="goIndex" class="btn home"></div>
              <div class="btn send"></div>
          </div>
      </div>
      <div class="main">
          <iframe src="https://static.loongvt.com/medical/upload/2023/11/06/42e56bb4-a07f-432c-90b3-2511b61eca68.pdf"  width="100%" height="100%"/>
      </div>
  </div>
</template>
<script>
import {useRouter} from 'vue-router'
export default {
    name: "Pdf",
    setup() {
        const router = useRouter()
        const goIndex = () => {
            router.push("/")
        }
        const goWelcome = () => {
            router.push("/welcome")
        }
        return{
            goIndex,
            goWelcome,
        }
    }
}
</script>
<style scoped>
.page{
    background-image: url("../assets/bg_liebiao.jpg");
    background-size: cover;
    overflow: hidden;
}

.logo{
    width: 235px;
    height: 93px;
    background: url("../assets/logo_2.png");
    background-size: 100%;


}
.header{
    display: flex;
    justify-content: space-between;

    padding: 35px 55px;
}
.operate{
    display: flex;
}
.operate .btn{
    width: 76px;
    height: 76px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    margin: 0 60px;
}
.operate .btn.welcome{
    background-image: url("../assets/come.png");
}
.operate .btn.home{
    background-image: url("../assets/home.png");
}
.operate .btn.send{
    background-image: url("../assets/send.png");
}
.operate .btn.back{
    background-image: url("../assets/back.png");
}

.main{
    height: calc(100% - 163px - 30px);
    background: #FFFFFF;
    border: 2px solid #267BDA;
    box-shadow: -10px 0px 8px 0px rgba(0,90,201,0.53);
    border-radius: 66px;

    margin: 0 30px 30px;
    box-sizing: border-box;

    overflow-y: scroll;
}
</style>
