<template>
    <div class="fill" >
        <div class="wrapper">
            <div class="fill-content">
                <img src="../assets/shuoming.png">
            </div>
            <div class="fill-close" @click="close"></div>
        </div>
    </div>
</template>

<script setup>

import { defineEmits } from 'vue'

const emit = defineEmits(["close"]);
const close = ()=>{
    emit("close");
}

</script>

<style scoped>
.fill{
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);

    display: flex;
    align-items: center;
    justify-content: center;
}
.fill .wrapper{
    width: 1660px;
    height: 80%;
    background: rgba(255,255,255,0.5);
    padding: 20px;
    border-radius: 35px;
    box-sizing: border-box;
    position: relative;
}
.fill .fill-content{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background: #FFFFFF;
    box-shadow: 0px 29px 125px 0px rgba(51,94,204,0.52);
    border-radius: 30px;

    padding: 113px;
    box-sizing: border-box;
}
.fill img{
    width: 100%;
}
.fill .fill-close{
    width: 162px;
    height: 162px;
    background: url("../assets/guanbi_2.png");
    position: absolute;
    right: -60px;
    top: -60px;
}
</style>
