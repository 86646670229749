<template>
<div class="page">
    <div class="header">
        <div @click="goIndex" class="logo"></div>
        <div class="operate">
            <div v-if="showInfo" @click="goBack" class="btn back"></div>
            <div @click="goWelcome" class="btn welcome"></div>
            <div @click="goIndex" class="btn home"></div>
            <div @click="goSend" class="btn send"></div>
        </div>
    </div>
    <div class="main">
        <div class="menu">
            <div class="item" :class="{ active : typeId == item.id }" @click="changeNav(item.id, index)" v-for="(item, index) in typeData" v-bind:key="index">
                <div class="icon"><img :src="item.iconSmall"></div>
                <div class="name">{{ item.name }}</div>
            </div>
            <div class="active-bg" :style="'top:' +(menuNav * 20)+ '%'"></div>
        </div>
        <div class="right">
            <div class="content">
                <Info v-if="showInfo" :id="showId"/>
                <div v-if="!showInfo" class="table">
                    <div class="tool">
                        <div class="nav">
                            <div class="item" :class="{ active : tab == 0 }" @click="changeTab(0)">按产品分类</div>
                            <div class="item" :class="{ active : tab == 1 }" @click="changeTab(1)">按适应症分类</div>
                            <div class="active-bg" :style="'left:' +(tab * 50)+ '%'"></div>
                        </div>
                        <div class="search">
                            <input v-model="searchKey" placeholder="搜索您要找的产品"><div class="btn" @click="onSearch"></div>
                        </div>
                    </div>

                    <div class="table-page" v-if="tab == 1">
                        <template v-for="(classify,index) in classifyData" v-bind:key="index">
                            <div class="group">
                                <div class="title">{{ classify.name }}</div>
                                <div class="list">
                                    <div class="item" @click="showDetail(content.id)" v-for="(content, contentIndex) in classify.productList" v-bind:key="contentIndex">
                                        <div class="cover">
                                            <img :src="content.cover">
                                        </div>
                                        <div class="name">{{ content.name }}</div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="table-page" v-else>
                        <div class="list">
                            <div class="item" @click="showDetail(content.id)" v-for="(content, contentIndex) in productData" v-bind:key="contentIndex">
                                <div class="cover">
                                    <img :src="content.cover">
                                </div>
                                <div class="name">{{ content.name }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script setup>
import Info from '@/components/Info'
import {onBeforeMount, ref} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import axios from 'axios'


const router = useRouter()
let id = useRoute().query.id;


        const searchKey = ref("");
        const classifyData = ref({});
        const syncClassifyData = () => {
            axios.get("/product/classify/list", {params:{typeId:typeId.value,searchKey:searchKey.value}}).then(({data:res}) => {
                if (res.code == 200) {
                    classifyData.value = res.data;
                } else if(res.code != -1) {
                    alert(res.msg);
                }
            })
        }
        const showInfo = ref(false);
        const showId = ref();
        const showDetail = (id) =>{
            showInfo.value = true;
            showId.value = id;
        }


        const goIndex = () => {
            router.push("/")
        }
        const goWelcome = () => {
            router.push("/welcome")
        }
        const goBack = () => {
            showInfo.value = false;
        }
const goSend = () => {
    router.push({path:"/",query:{showSend:true}})
}
        const typeId = ref(id)
        const menuNav = ref(0);
        const changeNav = (value, index) => {
            typeId.value = value;
            showInfo.value = false;
            menuNav.value = index;
            syncClassifyData();
            syncProductData();
        }
        const tab = ref(1)
        const changeTab = (value) => {
            tab.value = value;
        }

        const typeData = ref({});
        const syncData = () => {
            axios.get("/product/type/list").then(({data:res}) => {
                if (res.code == 200) {
                    typeData.value = res.data;
                    for (let i = 0; i< typeData.value.length; i++){
                        if(typeId.value == typeData.value[i].id){
                            menuNav.value = i;
                        }
                    }
                } else if(res.code != -1) {
                    alert(res.msg);
                }
            })
        }

        const productData = ref({});
        const syncProductData = () => {
            axios.get("/product/list",{params:{typeId:typeId.value,searchKey:searchKey.value}}).then(({data:res}) => {
                if (res.code == 200) {
                    productData.value = res.data;
                } else if(res.code != -1) {
                    alert(res.msg);
                }
            })
        }
        const onSearch = () => {
            syncClassifyData();
            syncProductData();
        }

        onBeforeMount(() => {
            syncData();
            syncClassifyData();
            syncProductData();
        })
</script>

<style scoped>
    .page{
        background-image: url("../assets/bg_liebiao.jpg");
        background-size: cover;
        overflow: hidden;
    }

    .logo{
        width: 235px;
        height: 93px;
        background: url("../assets/logo_2.png");
        background-size: 100%;


    }
    .header{
        display: flex;
        justify-content: space-between;

        padding: 35px 55px;
    }
    .operate{
        display: flex;
    }
    .operate .btn{
        width: 76px;
        height: 76px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;

        margin: 0 60px;
    }
    .operate .btn.welcome{
        background-image: url("../assets/come.png");
    }
    .operate .btn.home{
        background-image: url("../assets/home.png");
    }
    .operate .btn.send{
        background-image: url("../assets/send.png");
    }
    .operate .btn.back{
        background-image: url("../assets/back.png");
    }

    .main{
        display: flex;
        height: calc(100% - 163px);
    }
    .main .menu{
        width: 360px;
        height: calc(100% - 120px - 35px);
        margin: 60px 0;

        flex-shrink: 0;
        position: relative;

        z-index: 1;
    }
    .main .menu .item{
        height: 20%;
        padding: 25px 0;
        box-sizing: border-box;
        position: relative;
        z-index: 9;
    }
    .main .menu .item.active{
        /*background: #FFFFFF;*/
    }
    .main .menu .item.active .name{
        color: #0270BC;
    }
    .main .menu .icon{
        width: 200px;
        height: 200px;
        background-image: url("../assets/menu_1.png");
        background-size: contain;

        margin: 0 auto;
    }
    .main .menu .icon img{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
    .main .menu .name{
        font-size: 39px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 58px;
        text-align: center;
    }
    .main .menu .active-bg{
        width: 100%;
        height: 20%;
        background: #FFF;
        position: absolute;
        left: 0;
        top: 0;

        z-index: 1;

        -webkit-transition: all 0.3s;
    }

    .main .right{
        width: calc(100% - 360px);
        height: 100%;
        padding: 0 35px 35px 0;
        box-sizing: border-box;
    }
    .main .right .content{
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        border: 2px solid #267BDA;
        box-shadow: -10px 0px 8px 0px rgba(0,90,201,0.53);
        border-radius: 66px;

        margin-left: -3px;

        /*padding: 90px 120px;*/
        box-sizing: border-box;
    }
    .table{
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 95px 120px;

    }
    .table-page{
        width: 100%;
        height: calc(100% - 60px - 88px);
        box-sizing: border-box;
        overflow-y: scroll;
        overflow-x: hidden;
        margin-top: 60px;
        padding: 60px 0;
    }
    .tool{
        display: flex;
        justify-content: space-between;
    }
    .search{
        width: 599px;
        height: 88px;
        background: rgba(0,0,0,0);
        border: 4px solid #0451C5;
        border-radius: 48px;
        padding: 0 50px;

        display: flex;
        align-items: center;
        position: relative;
    }
    .search input{
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        outline:none;
        border: 0;
    }
    .search .btn{
        width: 92px;
        height: 92px;
        border-radius: 48px;
        background: #0451C5;
        position: absolute;
        right: -2px;
        top: -2px;

        display: flex;
        align-items: center;
        justify-content: center;
    }
    .search .btn:before{
        display: block;
        content: '';
        width: 46px;
        height: 46px;
        background: url("../assets/season.png") ;
        background-size: contain;
    }
    .nav{
        width: fit-content;
        height: 88px;
        background: rgba(0,0,0,0);
        border: 4px solid #0451C5;
        border-radius: 46px;

        display: flex;
        position: relative;
    }
    .nav .item{
        width: 325px;
        height: 88px;
        border-radius: 44px;

        font-size: 34px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #0451C5;
        line-height: 88px;
        text-align: center;

        margin: 0 -1px;

        position: relative;
        z-index: 9;
    }
    .nav .item.active{
        color: #FFF;
        /*background: #0451C5;*/
    }
    .nav .active-bg{
        width: 325px;
        height: 88px;
        border-radius: 44px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;
        background: #0451C5;
        margin: 0 -1px;


        -webkit-transition: all 0.3s;
    }
    .group{
        margin-bottom: 115px;
    }
    .title{
        font-size: 53px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #0451C5;
        line-height: 109px;

        margin-bottom: 70px;
    }
    .list{
        display: flex;
        flex-wrap: wrap;
        margin: -40px;
    }

    .list .item{
        width: 475px;
        margin: 40px;
    }
    .list .item .cover{
        width: 475px;
        height: 290px;
        border-radius: 20px;
        overflow: hidden;
    }
    .list .item .cover img{
        width: 100%;
        height: 100%;
    }
    .list .item .name{
        font-size: 39px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        line-height: 63px;

        margin-top: 20px;
    }
</style>
