<template>
<div class="page">
    <div class="logo"></div>
    <div class="operate">
        <div class="btn hi" @click="onShowName"></div>
        <div class="btn user" @click="onPeople"></div>
    </div>
    <div class="info">
        <div class="title" v-if="showName">尊敬的<span v-if="people">{{ people.name }}</span>：</div>
        <div class="desc">
            <p>欢迎您开启博迈之旅！<br>
                很荣幸能与您共同探索技术领先的<br>
                血管介入医疗器械。</p>
        </div>
        <div class="btn" @click="goIndex">开始旅程</div>
    </div>
    <div class="people" v-if="showPeople">
        <div class="wrapper">
            <div class="content">
                <div class="title">客户名单</div>
                <div class="btn-add" @click="addCustomer">新增客户</div>
                <div class="list">
                    <div class="item" v-for="(item, index) in data" v-bind:key="index">
                        <div class="name">{{ item.name }}</div>
                        <div class="mail">{{ item.hospital }}</div>
                        <div class="btn-group">
                            <div class="btn-item" v-if="chooseIndex == index">已选择</div>
                            <div class="btn-item" v-else @click="choose(index)">选择</div>
                            <div class="btn-item" @click="editCustomer(item)">修改</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="close" @click="onClose"></div>
        </div>
    </div>
    <div class="fill" v-if="showFill">
        <div class="wrapper">
            <div class="fill-content">
                <div class="title">新增客户</div>
                <div class="input-group">
                    <input v-model="customer.name" placeholder="请输入医生姓名" type="text"/>
                </div>
                <div class="input-group">
                    <input v-model="customer.hospital" placeholder="请输入医院名称" type="text"/>
                </div>
                <div class="btn" @click="saveCustomer">确认</div>
            </div>
            <div class="fill-close" @click="showFill = false;showPeople = true;"></div>
        </div>
    </div>

</div>
</template>

<script setup>
import {useRouter} from 'vue-router'
import {onBeforeMount, onMounted, reactive, ref} from 'vue'
import axios from 'axios'


        const showName = ref(false);
        const onShowName = () => {
            if(showName.value || people.value.name){
                showName.value = !showName.value
            }
        }
        const router = useRouter()
        const goIndex = () => {
            if(showName.value){
                axios.post("/visit/save", {id:people.value.id, ...location }).then(({data}) => {
                    if (data.code == 200) {
                        router.push("/")
                    } else if(data.code != -1) {
                        alert(data.msg);
                    }
                })
            }else{
                router.push("/")
            }
        }
        const showPeople = ref(false);
        const onPeople = () => {
            showPeople.value = true;
        }
        const onClose = () => {
            showPeople.value = false;
        }
        const data = ref([])
        const people = ref({})
        const chooseIndex = ref(-1);
        const choose = (index) => {
            people.value = data.value[index];
            chooseIndex.value = index;
            showName.value = true;
        }


        const showFill = ref(false);
        const customer = ref({});
        const addCustomer = () => {
            showFill.value = true;
            showPeople.value = false;
        }
        const saveCustomer = () => {
            if(customer.value.name){

                axios.post("/customer/save",{...customer.value}).then(({data:res}) => {
                    if (res.code == 200) {
                        syncData();
                    } else {
                        alert(res.msg);
                    }
                })
                showFill.value = false;
                showPeople.value = true;
                customer.value={};
            }
        }
        const editCustomer = (value) =>{
            customer.value = value;
            showFill.value = true;
            showPeople.value = false;
        }
const syncData = () => {
    axios.get("/customer/list").then(({data:res}) => {
        if (res.code == 200) {
            data.value = res.data;
        } else if(res.code != -1) {
            alert(res.msg);
        }
    })
}

onBeforeMount(() => {
    syncData();
})
const location = reactive({});
onMounted(()=>{
    window.AMap.plugin('AMap.Geolocation', function() {
        var geolocation = new window.AMap.Geolocation({
            // 是否使用高精度定位，默认：true
            enableHighAccuracy: true,
            // 设置定位超时时间，默认：无穷大
            timeout: 10000,
            // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
            buttonOffset: new window.AMap.Pixel(10, 20),
            //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
            zoomToAccuracy: true,
            //  定位按钮的排放位置,  RB表示右下
            buttonPosition: 'RB'
        })

        geolocation.getCurrentPosition()
        window.AMap.event.addListener(geolocation, 'complete', onComplete)
        window.AMap.event.addListener(geolocation, 'error', onError)

        function onComplete (data) {
            console.log(data);
            // data是具体的定位信息
            location.address = data.formattedAddress;
            location.lat= data.position.lat;
            location.lng = data.position.lng;
        }

        function onError (data) {
            console.log(data);
            // 定位出错
        }
    })
})
</script>

<style scoped>
.page{
    background: url("../assets/bg_welcome.jpg") center;
    background-size: cover;

    position: relative;
    overflow: hidden;
}
.logo{
    width: 397px;
    height: 158px;
    background: url("../assets/logo_white.png");

    position: absolute;
    top: 55px;
    left: 55px;
}
.operate{
    position: absolute;
    top: 40px;
    right: 120px;
    display: flex;
}
.operate .btn{
    width: 70px;
    height: 70px;
}
.operate .btn.user{
    background: url("../assets/icon_4.png");
    background-size: contain;
}
.operate .btn.hi{
    background: url("../assets/icon_3.png");
    background-size: contain;
    margin-right: 80px;
}
.info{
    margin: 515px auto 0;

    padding: 0 330px 0 680px;
}
.info .title{

    font-size: 140px;
    font-family: Source Han Sans CN;
    font-weight: 800;
    color: #FFE6BD;
    line-height: 120px;
    text-shadow: 0px 3px 2px #BC0505;
}
.info .desc{
    font-size: 110px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFF8E2;
    line-height: 160px;

    margin-top: 100px;
}
.info .subtitle{
    width: 1168px;
    height: 473px;
    background: url("../assets/welcome.png");
    background-size: 100%;
    position: relative;
    right: -100px;
    top: -80px;
}
.info .btn{
    width: 400px;
    height: 125px;
    background: linear-gradient(0deg, #F5EECC, #D9C485);
    border-radius: 20px;

    font-size: 50px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #C81A1B;
    line-height: 125px;
    text-align: center;

    margin-top: 200px;

    float: right;

}
.people{
    position: fixed;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);

    display: flex;
    align-items: center;
    justify-content: center;
}
.people .wrapper{
    position: relative;
    width: 1555px;
    height: 1223px;
    background: rgba(255,255,255,0.6);
    padding: 20px;
    border-radius: 30px;
}
.people .wrapper .content{
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border: 1px solid  rgba(51,94,204,0.52);
    border-radius: 30px;

    padding: 90px 100px;
    box-sizing: border-box;
}
.people .title{
    font-size: 80px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0053CF;
    line-height: 80px;
    text-align: center;
}
.people .btn-add{
    width: 199px;
    height: 91px;
    background: #0053CF;
    border-radius: 20px;

    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 91px;
    text-align: center;

    margin-top: 70px;
}
.people .list{
    margin-top: 40px;
    height: 800px;
    overflow-y: scroll;
}
.people .list .item{
    height: 160px;

    border-bottom:1px solid #DDDDDD;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.people .list .item:nth-child(1){
    border-top:1px solid #DDDDDD;
}
.people .list .item .name{
    width: 280px;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 46px;
}
.people .list .item .mail{
    width: 800px;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 46px;
}
.people .list .item .btn-group{
    height: 36px;
    flex-shrink: 0;
    width: 300px;
    display: flex;
    justify-content: flex-end;
}
.people .list .item .btn-group .btn-item:nth-child(1){
    border-right: 1px solid #0053CF;
}
.people .list .item .btn-group .btn-item{
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0053CF;
    line-height: 36px;
    text-align: center;
    padding: 0 25px;
}
.people .list .item .btn-group .btn-item:last-child{
    padding-right: 0px;
}
.people .close{
    width: 162px;
    height: 162px;
    background: url("../assets/guanbi_2.png");
    position: absolute;
    right: -60px;
    top: -60px;
}

.fill{
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);

    display: flex;
    align-items: center;
    justify-content: center;
}
.fill .wrapper{
    width: 840px;
    height: fit-content;
    background: rgba(255,255,255,0.5);
    padding: 20px;
    border-radius: 35px;
    box-sizing: border-box;
    position: relative;
}
.fill .fill-content{
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 29px 125px 0px rgba(51,94,204,0.52);
    border-radius: 30px;

    padding: 60px;
    box-sizing: border-box;
}
.fill .title{
    font-size: 60px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0451C5;
    line-height: 60px;
    text-align: center;
    margin-bottom: 70px;
}
.fill .input-group{
    width: 100%;
    height: 101px;
    background: #FFFFFF;
    border: 2px solid #0087D5;
    box-shadow: 0px 6px 6px 0px rgba(51,94,204,0);
    border-radius: 10px;
    margin-top: 40px;
    padding: 0 25px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
}

.fill .input-group input{
    width: 100%;
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0451C5;
    line-height: 48px;

    outline:none;
    border: 0;
}
/*.fill .input-group input::-webkit-input-placeholder {*/
/*    !* WebKit browsers，webkit内核浏览器 *!*/
/*    color: #0451C5;*/
/*}*/
.fill .btn{
    width: 287px;
    height: 91px;
    background: #0451C5;
    border-radius: 20px;

    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 91px;
    text-align: center;

    margin: 60px auto 0;
}
.fill .fill-close{
    width: 162px;
    height: 162px;
    background: url("../assets/guanbi_2.png");
    position: absolute;
    right: -60px;
    top: -60px;
}
</style>
