<template>
    <div class="fill" >
        <div class="wrapper">
            <div class="fill-content">
                <div class="title">拜访记录</div>
                <div class="list-head">
                    <div class="name">姓名</div>
                    <div class="hospital">医院</div>
                </div>
                <div class="list">
                    <div class="item" v-for="(item, index) in data" v-bind:key="index">
                        <div class="name">{{ item.name }}</div>
                        <div class="hospital">{{ item.hospital }}</div>
                    </div>
                </div>
            </div>
            <div class="fill-close" @click="close"></div>
        </div>
    </div>
</template>

<script setup>

import axios from 'axios'
import {onBeforeMount, ref} from 'vue'
import { defineEmits } from 'vue'

const emit = defineEmits(["close"]);
const close = ()=>{
    emit("close");
}
const data = ref([]);
const syncData = () => {
    axios.get("/visit/my").then(({data:res}) => {
        if (res.code == 200) {
            data.value = res.data;
        } else if(res.code != -1) {
            alert(res.msg);
        }
    })
}

onBeforeMount(() => {
    syncData();
})

</script>

<style scoped>
.fill{
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);

    display: flex;
    align-items: center;
    justify-content: center;
}
.fill .wrapper{
    width: 1660px;
    height: fit-content;
    background: rgba(255,255,255,0.5);
    padding: 20px;
    border-radius: 35px;
    box-sizing: border-box;
    position: relative;
}
.fill .fill-content{
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 29px 125px 0px rgba(51,94,204,0.52);
    border-radius: 30px;

    padding: 113px;
    box-sizing: border-box;
}
.fill .title{
    font-size: 80px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0053CF;
    line-height: 80px;
    text-align: center;
}
.list-head{
    height: 150px;
    background: #E9F3FD;
    padding: 0 70px;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #455773;
    line-height: 46px;

    margin-top: 80px;
}
.list-head .name{
    width: 30%;
}
.list-head .hospital{
    width: 70%;
}
.list{
    height: 803px;
    background: #F7FCFF;
    padding: 0 70px;
    box-sizing: border-box;

    margin-top: 10px;

    overflow-y: scroll;
}
.list .item{
    height: 170px;
    border-bottom: 1px solid #C7E0F1;
    display: flex;
    align-items: center;
}
.list .item .name{
    width: 30%;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #455773;
    line-height: 46px;
}
.list .item .hospital{
    width: 70%;
    font-size: 40px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #455773;
    line-height: 46px;
}
.fill .fill-close{
    width: 162px;
    height: 162px;
    background: url("../assets/guanbi_2.png");
    position: absolute;
    right: -60px;
    top: -60px;
}
</style>
