<template>
<div class="info">
    <div class="nav" v-if="advantageClass">
        <div class="item" :class="{ active : nav == 0 }" @click="changeNav(0)">产品介绍</div>
        <div class="item" :class="{ active : nav == 1 }" @click="changeNav(1)">特征优势</div>
        <div class="item" :class="{ active : nav == 2 }" @click="changeNav(2)">产品视频</div>
        <div class="item" :class="{ active : nav == 3 }" @click="changeNav(3)">技术参数</div>
        <div class="item" :class="{ active : nav == 4 }" @click="changeNav(4)">学术会议</div>
        <div class="item" :class="{ active : nav == 5 }" @click="changeNav(5)">文献荟萃</div>
        <div class="active-bg" :style="'left:' +(nav * (100/6))+ '%'"></div>
    </div>

    <div class="nav" v-else>
        <div class="item" :class="{ active : nav == 0 }" @click="changeNav(0)">产品介绍</div>
        <div class="item" :class="{ active : nav == 1 }" @click="changeNav(1)">产品视频</div>
        <div class="item" :class="{ active : nav == 2 }" @click="changeNav(2)">技术参数</div>
        <div class="item" :class="{ active : nav == 3 }" @click="changeNav(3)">学术会议</div>
        <div class="item" :class="{ active : nav == 4 }" @click="changeNav(4)">文献荟萃</div>
        <div class="active-bg" :style="'left:' +(nav * (100/5))+ '%'"></div>
    </div>

    <div class="info-page" id="page">
        <div class="module a" style="margin-top: 0px">
            <div class="left">
                <div class="line"></div>
                <div class="name">{{ info.name }}</div>
                <div class="small">{{info.description}}</div>
                <div class="line" style="margin-top: 80px"></div>
                <div class="good">
                    <template v-for="(item, index) in info.contentList" v-bind:key="index">
                        <div class="item"  v-if="item.type == 1">
                            <div class="icon"><img class="poster" :src="item.cover"></div>
                            <div>
                                <div class="good-name">{{ item.name }}:</div>
                                <div class="text">{{ item.text }}</div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="cover">
                <img :src="info.banner">
            </div>
<!--            <div class="file">-->
<!--                <div class="swiper">-->
<!--                        <div class="swiper-wrapper">-->
<!--                            <template v-for="(item, index) in info.contentList" v-bind:key="index">-->
<!--                                <div class="swiper-slide"  v-if="item.type == 5">-->
<!--                                    <div class="item">-->
<!--                                        <div class="file-name">{{ item.name }}</div>-->
<!--                                        <div class="btn" @click="onShowPdf(item.src)">查看</div>-->
<!--                                    </div>-->
<!--                                </div>-->
<!--                            </template>-->
<!--                        </div>-->
<!--                    </div>-->
<!--            </div>-->
        </div>
<!--        <div v-if="info.id == 5" class="module advantage" :class="{act : nav == 1 || advantageClick}">-->
<!--            <div class="title">特征优势</div>-->
<!--            <div class="wrapper">-->
<!--                <img src="../assets/advantage/bg.png">-->
<!--                    <div :class="{show : advantage[0].show, out : advantage[0].hide}">-->
<!--                        <div class="p p1" >-->
<!--                            <div class="btn"  @click="showP(0)"></div>-->
<!--                        </div>-->
<!--                        <div class="l l1"></div>-->
<!--                        <div class="w w1"></div>-->
<!--                    </div>-->

<!--                    <div :class="{show : advantage[1].show, out : advantage[1].hide}">-->
<!--                        <div class="p p2" >-->
<!--                            <div class="btn"  @click="showP(1)"></div>-->
<!--                        </div>-->
<!--                        <div class="l l2" ></div>-->
<!--                        <div class="w w2"></div>-->
<!--                    </div>-->
<!--                    <div  :class="{show : advantage[2].show, out : advantage[2].hide}">-->
<!--                        <div class="p p3">-->
<!--                            <div class="btn"  @click="showP(2)"></div>-->
<!--                        </div>-->
<!--                        <div class="l3"></div>-->
<!--                        <div class="w w3"></div>-->
<!--                    </div>-->
<!--                    <div  :class="{show : advantage[3].show, out : advantage[3].hide}">-->
<!--                        <div class="p p4" @click="showP(3)">-->
<!--                        </div>-->
<!--                        <div class="l l4"></div>-->
<!--                        <div class="w w4"></div>-->
<!--                    </div>-->

<!--            </div>-->
<!--        </div>-->
        <div v-if="advantageClass" class="module advantage b " :class="{act : nav == 1 && !advantageClick}">
            <div class="title">特征优势</div>
            <div :class="'wrapper '+ advantageClass">
                <div class="hand"></div>
                <div :class="{show : advantage[0].show, out : advantage[0].hide}">
                    <div class="p p1" @click="showP(0)"></div>
                    <div class="p p1 part2" @click="showP(0)"></div>
                    <div class="w w1"></div>
                    <div class="l l1"></div>
                </div>
                <div :class="{show : advantage[1].show, out : advantage[1].hide}">
                    <div class="p p2" @click="showP(1)"></div>
                    <div class="w w2"></div>
                    <div class="l l2"></div>
                </div>
                <div  :class="{show : advantage[2].show, out : advantage[2].hide}">
                    <div class="p p3" @click="showP(2)"></div>
                    <div class="w w3"></div>
                    <div class="l l3"></div>
                </div>
                <div  :class="{show : advantage[3].show, out : advantage[3].hide}">
                    <div class="p p4" @click="showP(3)"></div>
                    <div class="w w4"></div>
                    <div class="l l4"></div>
                </div>
                <div  :class="{show : advantage[4].show, out : advantage[4].hide}">
                    <div class="p p5" @click="showP(4)"></div>
                    <div class="w w5"></div>
                    <div class="l l5"></div>
                </div>
                <div  :class="{show : advantage[5].show, out : advantage[5].hide}">
                    <div class="p p6" @click="showP(5)"></div>
                    <div class="w w6"></div>
                </div>
                <div  :class="{show : advantage[6].show, out : advantage[6].hide}">
                    <div class="p p7" @click="showP(6)"></div>
                    <div class="w w7"></div>
                </div>

            </div>
        </div>


        <div class="module video" :class="{ four : videoLen > 3}">
            <div class="title">产品视频</div>
            <div class="list">
                <div class="swiper">
                    <div class="swiper-wrapper">
                        <template v-for="(item, index) in info.contentList" v-bind:key="index">
                            <div class="swiper-slide"  v-if="item.type == 2">
                                <div class="item">
                                    <div class="poster" @click="onPlay(item.src)">
                                        <img :src="item.cover">
                                    </div>
                                    <div class="content">
                                        <div class="name">{{ item.name }}</div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="module img">
            <div class="title">技术参数</div>
            <img class="image" :src="info.img">
        </div>
        <div class="module meeting">
            <div class="title">学术会议</div>
            <div class="meeting-swiper">
                <div class="swiper">
                    <div class="swiper-wrapper">
                        <template v-for="(item, index) in info.contentList" v-bind:key="index">
                            <div class="swiper-slide"  v-if="item.type == 3">
                                <div class="item">
                                    <div class="meeting-v" @click="onPlay(item.src)">
                                        <img class="poster" :src="item.cover">
                                        <div class="name">{{ item.name }}</div>
                                    </div>
                                    <div class="line"></div>
                                    <div class="date">
                                        {{ item.text2 }}
                                    </div>
                                    <div class="desc">
                                        {{ item.text }}
                                    </div>
                                    <div class="line"></div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="prev" @click="prev" :class="{'disabled':meetSwiper.isBeginning}"></div>
                <div class="next" @click="next" :class="{'disabled':meetSwiper.isEnd}"></div>
            </div>
        </div>
        <div class="module download">
            <div class="title">文献荟萃</div>
            <div class="list">
                <template v-for="(item, index) in info.contentList" v-bind:key="index">
                    <div class="item"  v-if="item.type == 4">
                        <div class="name">{{ item.name }}</div>
                        <div class="desc">{{ item.text }}</div>
                        <div class="btn" @click="onShowPdf(item.src)">查看PDF</div>
                        <div class="code">
                            <QrcodeVue :value="item.src" :size="125"  ></QrcodeVue>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
    <div class="play" v-if="showPlay">
        <div class="content">
            <video  id="video"
                width="100%"
                   height="100%"
                   poster="../assets/pic_vc.png"
                   :src="playSrc" controls="controls"></video>
<!--            <video-play-->
<!--                width="100%"-->
<!--                height="100%"-->
<!--                v-if="showPlay"-->
<!--                poster="../assets/pic_vc.png"-->
<!--                :src="playSrc"-->
<!--                :webFullScreen="true"-->
<!--                :control="true"-->
<!--            />-->
            <div class="close" @click="showPlay = false"></div>
        </div>
    </div>
    <div class="pdf" v-if="showPdf">
        <div class="content">
            <iframe :src="'static/pdf/web/viewer.html?file=' + pdf" width="100%" height="100%"/>
            <div class="close" @click="showPdf = false"></div>
        </div>
    </div>
</div>
</template>

<script setup>
import {onBeforeMount, onMounted, reactive, ref} from 'vue'
import { defineProps } from 'vue'
import { Swiper } from 'swiper';
import 'swiper/swiper-bundle.css';
import axios from 'axios'
import QrcodeVue from 'qrcode.vue'
// import {videoPlay} from 'vue3-video-play'
//
// import 'vue3-video-play/dist/style.css';


const props = defineProps({
    id:Number
})
const nav = ref(0);

const changeNav = (value) => {
    nav.value = value;
    let modules = document.getElementsByClassName("module");
    let scrollTop = modules[value].offsetTop;
    document.getElementById("page").scrollTop = scrollTop - 450;
}
const showPdf = ref(false);
const pdf=ref("");
const onShowPdf = (src) => {
    pdf.value = src;
    showPdf.value = true;
}
const showPlay = ref(false);
const playSrc = ref("");
const onPlay = (src)=>{
    playSrc.value = src;
    showPlay.value = true;
    setTimeout(function (){
        document.getElementById("video").play();
        var ele = document.getElementById("video");
        if (ele.requestFullscreen) {
            ele.requestFullscreen();
        } else if (ele.mozRequestFullScreen) {
            ele.mozRequestFullScreen();
        } else if (ele.webkitRequestFullScreen) {
            ele.webkitRequestFullScreen();
        }
    },500);
}
const info = ref();
const videoLen = ref(0);
onBeforeMount(() => {
    axios.get("/product/detail", {params:{id:props.id}}).then(({data:res}) => {
        if (res.code == 200) {
            info.value = res.data;
            for (const item of res.data.contentList){
                console.log(item)
                if(item.type == 2){
                    videoLen.value ++;
                }
            }

            setTimeout(function (){
                swiper.value = new Swiper('.video .swiper', {
                    // spaceBetween: -1800,
                    slidesPerView: videoLen.value > 3 ? 4 : 2,
                    navigation: true,
                    on: {
                        tap: function () {
                        },
                    }
                });
                meetSwiper.value = new Swiper('.meeting .swiper', {
                    navigation: true,
                    on: {
                        tap: function () {
                        },
                    }
                });
                fileSwiper.value = new Swiper('.file .swiper', {
                    slidesPerView: 2,
                    spaceBetween: 33,
                    navigation: true,
                    on: {
                        tap: function () {
                        },
                    }
                });
            }, 1500);

        } else if(res.code != -1) {
            alert(res.msg);
        }
    })
})
const swiper = ref({});
const meetSwiper = ref({});
const fileSwiper = ref({});
const next = () => {
    meetSwiper.value.slideNext();
}
const prev = () => {
    meetSwiper.value.slidePrev();
}

const advantage = reactive([
    {show:false,hide:false},
    {show:false,hide:false},
    {show:false,hide:false},
    {show:false,hide:false},
    {show:false,hide:false},
    {show:false,hide:false},
    {show:false,hide:false},
])
const advantageClick = ref(false);
const showP = (index) => {
    advantageClick.value = true;
    for (let i = 0; i < advantage.length; i++) {
        console.log(advantage[i].show)
        if(advantage[i].show == true){
            advantage[i].show = false;
            advantage[i].hide = true;
        }
    }
    advantage[index].hide = false;
    advantage[index].show = true;
}
const advantageClass = ref("");
onMounted(() => {

    switch (props.id){
        case 1: advantageClass.value = 'pot'; break;
        case 2: advantageClass.value = 'pronavi';break;
        case 3: advantageClass.value = 'alveo';break;
        case 4: advantageClass.value = 'wedge';break;
        case 5: advantageClass.value = 'tri';break;
        case 6: advantageClass.value = 'tiche';break;
        case 8: advantageClass.value = 'bachilles';break;
        case 10: advantageClass.value = 'pregard';break;
        case 11: advantageClass.value = 'brontes';break;
    }


    setTimeout(function (){
        document.getElementById("page").addEventListener('scroll', function (){
            let ele = document.getElementById("page");
            let scrollTop = ele.scrollTop
            console.log(scrollTop, '滚动距离')
            let modules = document.getElementsByClassName("module");
            for (let i = 0; i< modules.length; i++){
                if(modules[i].offsetTop - 500 < scrollTop){
                    console.log(i, 'aaaaaaaaa')

                    nav.value = i;
                }
            }
        });
    },3000);


})

</script>

<style >
@import "../assets/advantage/b.css";

.info{
    width: 100%;
    height: 100%;
    padding: 95px 120px;
    box-sizing: border-box;
}
.info-page{
    width: 100%;
    height: calc(100% - 80px);
    overflow: scroll;

    margin-top: 80px;
    padding: 100px 0 500px;
    box-sizing: border-box;
}
.nav{
    width: fit-content;
    height: 88px;
    background: rgba(0,0,0,0);
    border: 4px solid #0451C5;
    border-radius: 46px;

    display: flex;

    position: relative;
}
.nav .item{
    width: 266px;
    height: 88px;
    border-radius: 44px;

    font-size: 34px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #0451C5;
    line-height: 88px;
    text-align: center;

    margin: 0 -1px;

    position: relative;
    z-index: 9;
}
.nav .item.active{
    color: #FFF;
    /*background: #0451C5;*/
}
.nav .active-bg{
    width: 266px;
    height: 88px;
    border-radius: 44px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background: #0451C5;
    margin: 0 -1px;


    -webkit-transition: all 0.3s;
}
.module{
    margin-top: 200px;
}
.title{
    font-size: 140px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0053CF;
    line-height: 140px;
}
.a{
    /*display: flex;*/
    /*height: 1180px;*/
}
.a .left{
    /*flex-shrink: 0;*/
    /*width: 815px;*/
    /*margin-right: 75px;*/
}
.a .name{
    font-size: 74px;
    font-family: MiSans;
    font-weight: bold;
    color: #0451C5;
    text-align: center;

    margin-top: 30px;
}
.a .small{
    font-size: 26px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #777777;
    line-height: 35px;
    margin-top: 31px;
    text-align: center;
    white-space: pre;
}
.a .desc{
    font-size: 26px;
    font-family: Source Han Sans CN;
    color: #333333;
    line-height: 40px;

    margin-top: 35px;
}
.a .line{
    height: 3px;
    background: #0451C5;
}
.a .good .item{
    display: flex;
    margin-top: 100px;
}
.a .good .icon{
    width: 130px;
    height: 130px;
    margin-right: 60px;

    flex-shrink: 0;
}
.a .good .icon img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.a .good .good-name{
    font-size: 50px;
    font-family: MiSans;
    font-weight: bold;
    color: #0451C5;
    line-height: 50px;
}
.a .good .text{
    font-size: 36px;
    font-family: MiSans;
    font-weight: 400;
    color: #777777;
    line-height: 42px;
    margin-top: 30px;
}
.a .cover{
    width: 100%;
    height: auto;
    overflow: hidden;
    border-radius: 20px;
    margin-top: 90px;
}
.a .cover img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.a .file{
    margin-top: 40px;
}
.a .file .item::before{
    flex-shrink: 0;
    display: block;
    content: '';
    width: 124px;
    height: 124px;
    background: url("../assets/icon_wenjian.png");
    margin-right: 40px;
}
.a .file .item{
    width: 100%;
    height: 240px;
    background: #F4F4F4;
    border-radius: 20px;
    padding: 55px 58px;
    box-sizing: border-box;

    display: flex;
   align-items: center;
}
.a .file .file-name{
    flex-shrink: 0;
    width: 500px;
    font-size: 42px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #0451C5;
    line-height: 66px;
    margin-right: 45px;
}
.a .file .btn{
    width: 220px;
    height: 83px;
    background: #0451C5;
    border-radius: 20px;

    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 83px;
    text-align: center;
}
.video{
    position: relative;
}
.video .list{
    width: 100%;
    margin-top: 30px;
}
.video .item{
    width: 1050px;
    border-radius: 20px;
    overflow: hidden;

    background: url("../assets/bg_vc.png") no-repeat;
    background-size: cover;
}

.video .poster{
    width: 100%;
    height: 700px;

    position: relative;
}
.video .poster img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.video .poster::after{
    display: block;
    content: '';
    background: url("../assets/play.png");
    width: 198px;
    height: 199px;
    position: absolute;
    right: calc(50% - 99px);
    bottom: calc(50% - 99px);
}
.video .content{
    width: 100%;
    height:350px;

    padding: 60px 75px 0;
    box-sizing: border-box;


}
.video .content .name{
    font-size: 48px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 80px;
}
.video .content .tms{
    font-size: 48px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 72px;

    margin-top: 80px;
}
.video .content .btn{
    width: 287px;
    height: 91px;
    border: 4px solid #FFFFFF;
    border-radius: 20px;

    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 91px;
    text-align: center;

    margin-top: 115px;
}
.video .swiper-slide{
    width: 1350px;
    height: 1050px;
}
.video.four .item{
    width: 500px;
}
.video.four .content{
    height: 340px;
    padding: 35px;
}
.video.four .poster{
    height: 280px;
}
.video.four .poster::after{
    background-size: contain;
    width: 100px;
    height: 100px;
    right: calc(50% - 50px);
    bottom: calc(50% - 50px);
}
.video.four .content .name{
    font-size: 40px;
    color: #FFFFFF;
    line-height: 68px;
}
.video.four .swiper-slide{
    width: 500px;
    height: 630px;
}
.img .image{
    width: 100%;
    margin-top: 30px;
}

.meeting-swiper{
    margin-top: 80px;
    position: relative;
}
.meeting .item{
    width: 1675px;
    margin: auto;
}
.meeting-v{
    width: 1675px;
    height: 1025px;
    border-radius: 15px;
    position: relative;

    margin-bottom: 55px;
}
.meeting-v .poster{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.meeting-v .name{
    font-size: 48px;
    font-family: Source Han Sans CN;
    font-weight: 800;
    color: #FFFFFF;
    line-height: 72px;

    position: absolute;
    left: 0px;
    bottom: 0px;

    padding: 45px 75px;
    box-sizing: border-box;
}
.meeting-v::after{
     display: block;
     content: '';
     background: url("../assets/play.png");
     width: 198px;
     height: 199px;
     position: absolute;
     right: 50%;
     top: 50%;
    margin: -99px;
 }
.meeting .date{
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    line-height: 64px;

    margin-top: 45px;
}
.meeting .desc{
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 48px;

    margin-top: 40px;
    margin-bottom: 60px;
}
.meeting .line{
    width: 100%;
    height: 3px;
    background: #0451C5;
}
.meeting .prev{
    position: absolute;
    left: 0;
    top: 485px;
    width: 75px;
    height: 144px;
    background: url("../assets/left_2.png");
    z-index: 100;
}
.meeting .prev.disabled{
    background: url("../assets/left_1.png");
}
.meeting .next{
    position: absolute;
    right: 0;
    top: 485px;
    width: 75px;
    height: 144px;
    background: url("../assets/right_2.png");
    z-index: 100;
}
.meeting .next.disabled{
    background: url("../assets/right_1.png");
}
.download .list{
    padding: 110px 0;

    border-top: 2px solid #0451C5;
    border-bottom: 2px solid #0451C5;

    margin-top: 75px;
}
.download .item{
    height: 150px;
    display: flex;
    margin-top: 70px;
}
.download .item:nth-child(1){
    margin-top: 0;
}
.download .item::before{
    flex-shrink: 0;
    display: block;
    content: '';
    width: 124px;
    height: 124px;
    background: url("../assets/icon_wenjian.png");
    margin-right: 40px;
}
.download .name{
    width: 730px;
    flex-shrink: 0;
    margin-right: 45px;

    font-size: 48px;
    font-family: Source Han Sans CN;
    font-weight: 800;
    color: #0451C5;
    line-height: 72px;
}
.download .desc{
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 48px;
}
.download .btn{
    flex-shrink: 0;
    margin-top: 33px;
    margin-left: 55px;
    width: 287px;
    height: 91px;
    background: #0451C5;
    border-radius: 20px;

    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 91px;
    text-align: center;
}
.download .code{
    margin-left: 140px;
    box-sizing: border-box;
    flex-shrink: 0;
    width: 147px;
    height: 147px;
    background: #FFFFFF;
    border: 3px solid;
    border-image: linear-gradient(-31deg, #015CE5, #49C9FF) 3 3;

    display: flex;
    align-items: center;
    justify-content: center;
}

.play{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;

    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);

    display: flex;
    align-items: center;
    justify-content: center;
}
.play .content{
    width: 2040px;
    height: 1182px;
    background: url("../assets/bg_shipin.png");
    background-size: cover;
    border-radius: 60px;

    padding: 35px;
    position: relative;
}
.play .content video{
    width: 100%;
    height: 100%;
    border-radius: 60px;
    object-fit: cover;
}
.play .close{
    width: 130px;
    height: 130px;
    background: url("../assets/guanbi.png");
    position: absolute;
    top: -50px;
    right: -50px;
}
.pdf{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;

    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);

    display: flex;
    align-items: center;
    justify-content: center;
}
.pdf .content{
    width: 2040px;
    height: 1182px;
    background: url("../assets/bg_shipin.png");
    background-size: cover;
    border-radius: 60px;

    padding: 35px;
    position: relative;
}
.pdf .content video{
    width: 100%;
    height: 100%;
    border-radius: 60px;
    object-fit: cover;
}
.pdf .close{
    width: 130px;
    height: 130px;
    background: url("../assets/guanbi.png");
    position: absolute;
    top: -50px;
    right: -50px;
}
</style>
