<template>
  <div class="page">
      <div class="header">
          <div @click="goIndex" class="logo"></div>
          <div class="operate">
              <div @click="goIndex" class="btn back"></div>
              <div @click="goWelcome" class="btn welcome"></div>
              <div @click="goIndex" class="btn home"></div>
              <div @click="goSend" class="btn send"></div>
          </div>
      </div>
      <div class="main">
          <div class="login-name" v-if="loginUser">你好，{{ loginUser.name }}</div>
          <div class="login-name" v-else @click="goLogin">未登录</div>

            <div class="list">
                <div class="item" @click="showUpPassword = true">
                    <img class="icon" src="../assets/xiugai.png">
                    <div class="name">修改密码</div>
                </div>
                <div class="item" @click="showVisit = true">
                    <img class="icon" src="../assets/kehu.png">
                    <div class="name">拜访记录</div>
                </div>
                <div class="item" @click="showExplain = true">
                    <img class="icon" src="../assets/caozuo.png">
                    <div class="name">操作说明</div>
                </div>
            </div>
      </div>
      <UpPassword @close="showUpPassword = false" v-if="showUpPassword"/>
      <Visit  @close="showVisit = false" v-if="showVisit"/>
      <Explain  @close="showExplain = false" v-if="showExplain"/>
  </div>
</template>
<script>
import {useRouter} from 'vue-router'
import UpPassword from '@/components/UpPassword'
import {ref} from 'vue'
import Visit from '@/components/Visit'
import Explain from '@/components/Explain'

let user = JSON.parse(localStorage.getItem('user'));

export default {
    name: "About",
    components: {Explain, Visit, UpPassword},
    setup() {
        const showUpPassword = ref(false);
        const showVisit = ref(false);
        const showExplain = ref(false);
        const loginUser = ref(user);
        const router = useRouter()
        const goIndex = () => {
            router.push("/")
        }
        const goWelcome = () => {
            router.push("/welcome")
        }
        const goLogin = () => {
            router.push("/login")
        }
        const goSend = () => {
            router.push({path:"/",query:{showSend:true}})
        }
        return{
            showUpPassword,
            showVisit,
            showExplain,
            loginUser,
            goIndex,
            goWelcome,
            goSend,
            goLogin
        }
    }
}
</script>
<style scoped>
.page{
    background-image: url("../assets/bg_liebiao.jpg");
    background-size: cover;
    overflow: hidden;
}

.logo{
    width: 235px;
    height: 93px;
    background: url("../assets/logo_2.png");
    background-size: 100%;
}
.header{
    display: flex;
    justify-content: space-between;

    padding: 35px 55px;
}
.operate{
    display: flex;
}
.operate .btn{
    width: 76px;
    height: 76px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    margin: 0 60px;
}
.operate .btn.welcome{
    background-image: url("../assets/come.png");
}
.operate .btn.home{
    background-image: url("../assets/home.png");
}
.operate .btn.send{
    background-image: url("../assets/send.png");
}
.operate .btn.back{
    background-image: url("../assets/back.png");
}

.main{
    height: calc(100% - 163px - 30px);
    border: 2px solid #267BDA;
    box-shadow: -10px 0px 8px 0px rgba(0,90,201,0.53);
    border-radius: 66px;
    background-image: url("../assets/bg_2.jpg");
    background-size: cover;

    margin: 0 30px 30px;
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
}
.list{
    display: flex;
}
.item{
    background-image: url("../assets/bg_shezhi.png");
    background-size: 100% 100%;
    width: 715px;
    height: 875px;
    overflow: hidden;
}
.item .icon{
    display: block;
    width: 245px;
    height: 241px;
    object-fit: contain;
    margin: 235px auto 0;
}
.item .name{
    font-size: 56px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #0451C5;
    text-align: center;

    margin: 130px auto;
}

.login-name{
    width: fit-content;
    height: 91px;
    background: rgba(255,255,255,0.3);
    border: 1px solid rgba(100,169,234,0.48);
    border-radius: 45px;
    padding: 0 50px;
    box-sizing: border-box;

    position: absolute;
    left: 105px;
    top: 75px;

    font-size: 38px;
    font-weight: 400;
    color: #1F457D;
    line-height: 30px;
    line-height: 91px;
}
</style>
